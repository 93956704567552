import React, { useState } from "react";
import * as S from "./FeedbackModal.styled";
import { useTranslation } from "../../hooks";

interface FeedbackModalProps {
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const { message } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (feedback.trim() === "") {
      setError(message("feedbackError"));
      return;
    }
    onSubmit(feedback);
    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
    if (e.target.value.trim() !== "") {
      setError("");
    }
  };

  return (
    <S.ModalWrapper>
      <S.ModalContent>
        <S.StyledTextarea
          value={feedback}
          onChange={handleTextareaChange}
          rows={1}
          placeholder={message("feedbackPlaceholder")}
          required
        />
        <S.ButtonRow>
          <S.StyledButton onClick={handleSubmit}>
            {message("submitButton")}
          </S.StyledButton>
          <S.StyledButton onClick={onClose}>
            {message("cancelButton")}
          </S.StyledButton>
        </S.ButtonRow>
        {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      </S.ModalContent>
    </S.ModalWrapper>
  );
};
