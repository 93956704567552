import React from "react";
import * as S from "./Login.styled";
import { Login as LoginComponent } from "../../components/Login";

export const Login = () => {
  return (
    <S.Login>
      <LoginComponent />
    </S.Login>
  );
};
