import React from "react";
import { HeroImg } from "./HeroImg";
import * as S from "./LandingHero.styled";
import { useTranslation } from "../../hooks";

export const LandingHero = () => {
  const { message } = useTranslation();

  return (
    <>
      <S.ContainerMobile>
        <S.ImageWrapper>
          <HeroImg />
        </S.ImageWrapper>
        <S.HeroTitle>
          <span>{message("heroTitleLine1")}</span>
          <span>{message("heroTitleLine2")}</span>
        </S.HeroTitle>
      </S.ContainerMobile>
      <S.ContainerDesktop>
        <HeroImg />
      </S.ContainerDesktop>
    </>
  );
};
