import React from "react";
import * as S from "./Message.styled";
import { LoadingDots } from "..";
export const MessageLoading: React.FC = () => {
  return (
    <S.Wrapper position="left">
      <S.ContentWrapper position="left">
        <LoadingDots />
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
