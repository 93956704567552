import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";
import { Button as ButtonComponent } from "../Button";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const FormContainer = styled.div`
  border-radius: 8px;
  padding: 2rem;
  padding-bottom: 1.25rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 55%;

  ${mediaQueries.mobile} {
    width: 90%;
    box-shadow: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
`;

export const Logo = styled.img`
  width: 50%;
`;

export const Button = styled(ButtonComponent)(
  ({ theme }) => css`
    background-color: ${theme.colors.brand.primary.main};
    border-radius: 74px;
    width: 95%;
    cursor: pointer;
    color: ${theme.colors.contrast.light};
    border: none;
    padding: 12px;

    &:hover {
      opacity: 0.9;
    }

    span {
      font-size: 1.25rem;
    }
  `
);

export const ErrorMessage = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.text.error};
    font-size: 1rem;
    font-weight: bold;
    height: 20px;
  `
);
