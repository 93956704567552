export const colors = {
  brand: {
    primary: {
      light: "#e6f0ff",
      main: "#0d88d3",
      dark: "#004494",
    },
    secondary: {
      main: "#fee011",
    },
  },
  text: {
    primary: "#ffffff",
    secondary: "#000000",
    error: "#ff0000",
  },
  contrast: {
    dark: "#000000",
    middle: "#838383",
    light: "#ffffff",
  },
};
