import styled, { css } from "styled-components";
import { Button } from "../Button";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Wrapper = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  left: 20px;

  ${mediaQueries.mobile} {
    left: unset;
  }
`;

export const Container = styled.div(
  ({ theme }) => css`
    width: 90%;
    display: flex;
    padding: 27px 25px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-align: center;
    border-radius: 10.48px;
    background: ${theme.colors.brand.primary.main};
    gap: 20px;
  `
);

export const Text = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.contrast.light};
    font-size: 18.48px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.4%;
    letter-spacing: -0.554px;
    margin: 0;

    a {
      color: ${theme.colors.contrast.light};
    }
  `
);

export const StyledButton = styled(Button)`
  border: 0px;
  padding: 9px 20px;
  width: 100%;

  span {
    font-size: 18.48px;
    font-weight: 400;
  }
`;
