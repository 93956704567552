import styled from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  margin: 0 auto;
  width: min(70rem, 95%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.4rem 1rem 4rem;
  gap: 10px;

  ${mediaQueries.tablet} {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    padding-top: 1.5rem;
  }
`;

export const Logo = styled.img`
  width: 180px;
`;

export const LanguageLogoutWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
