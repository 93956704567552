import React from "react";
import { colors } from "../../styles/colors";

interface ArrowIconProps {
  isDisabled: boolean;
}

export const ArrowIcon = ({ isDisabled }: ArrowIconProps) => {
  const path =
    "M13.0878 14.3026L18.9601 9.03841M10.1792 17.3299L15.2513 24.4395C15.7554 25.1461 16.8344 25.0458 17.1998 24.2585L26.8488 3.46591C27.2765 2.54418 26.3674 1.56929 25.4181 1.93173L2.16497 10.8098C1.25697 11.1565 1.17917 12.4109 2.03738 12.8671L9.78897 16.9878C9.94378 17.07 10.0774 17.1872 10.1792 17.3299Z";

  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isDisabled ? (
        <path
          d={path}
          stroke="#CCCCCC"
          strokeWidth="1.98666"
          strokeLinecap="round"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke={colors.brand.primary.main}
          d={path}
          strokeWidth="1.98666"
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};
