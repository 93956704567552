import React from "react";
import "./App.css";
import { Chat, Home, Login } from "./templates";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { AssistantChatProvider } from "./context";
import { PrivateRoute } from "./components";
import { LanguageProvider } from "./context/LanguageContext";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: min(65rem, 100%);
  overflow: visible;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const ScrollContainer = styled.div`
  height: 100dvh;
  overflow-y: scroll;
`;

const LoginRoute = () => (
  <PageContainer>
    <Login />
  </PageContainer>
);

const HomeRoute = () => (
  <PageContainer>
    <Home />
  </PageContainer>
);

const ChatRoute = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = (messageIndex: number | string) => {
    const scrollMessageRef = document.getElementById(
      `message-right-${messageIndex}`
    );
    if (scrollMessageRef) {
      const offsetTop = scrollMessageRef.offsetTop;
      const viewHeight = scrollMessageRef.clientHeight + 50; // gap between messages
      containerRef.current?.scroll({
        top: offsetTop - viewHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <ScrollContainer ref={containerRef}>
      <PageContainer>
        <Chat scrollToBottom={scrollToBottom} />
      </PageContainer>
    </ScrollContainer>
  );
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginRoute />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <HomeRoute />
      </PrivateRoute>
    ),
  },
  {
    path: "/chat",
    element: (
      <PrivateRoute>
        <ChatRoute />
      </PrivateRoute>
    ),
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <AssistantChatProvider>
          <RouterProvider router={router} />
        </AssistantChatProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
