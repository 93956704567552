import React, { useState } from "react";
import * as S from "./Message.styled";
import Markdown from "react-markdown";
import { Annotation, MessageStatus } from "../../types";
import { DislikeIcon, LikeIcon } from "..";
import { useAssistantChat } from "../../context";
import { FeedbackModal } from "../FeedbackModal";
import { useTranslation } from "../../hooks";

interface MessageProps {
  content?: string;
  position: "left" | "right";
  id: string;
  withoutMarginBottom?: boolean;
  annotations?: Annotation[];
  messageId?: string;
  status: MessageStatus;
}

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_LOCAL;

export const Message: React.FC<MessageProps> = ({
  content,
  position,
  id,
  withoutMarginBottom,
  annotations,
  messageId,
  status,
}) => {
  const { handleMessageStatusChange } = useAssistantChat();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { message } = useTranslation();

  const isLiked = status === MessageStatus.Liked;
  const isDisliked = status === MessageStatus.Disliked;
  const isLeftPosition = position === "left";

  const handleDislikeClick = () => {
    if (messageId && !isDisliked) {
      setIsModalOpen(true);
    }
  };

  const handleLikeClick = async () => {
    if (messageId && !isLiked) {
      await handleMessageStatusChange(messageId, MessageStatus.Liked);
    }
  };

  const handleFeedbackSubmit = async (feedback: string) => {
    if (messageId) {
      await handleMessageStatusChange(
        messageId,
        MessageStatus.Disliked,
        feedback
      );
    }
  };

  const fileNameSet = new Set<string>();

  return (
    <S.Wrapper
      position={position}
      id={id}
      withoutMarginBottom={withoutMarginBottom}
    >
      <S.ContentWrapper position={position}>
        <Markdown>{content}</Markdown>
        {annotations && annotations.length > 0 && (
          <S.Source>
            {message("sources")}&nbsp;
            {annotations
              .filter((annotation) => {
                const filename = annotation.filename;
                const isDuplicate = fileNameSet.has(filename);
                fileNameSet.add(filename);
                return !isDuplicate;
              })
              .map((annotation: Annotation) => (
                <a
                  key={annotation.file_citation.file_id}
                  href={`${BACKEND_URL}/download/${annotation.filename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {annotation.filename}
                </a>
              ))}
          </S.Source>
        )}
      </S.ContentWrapper>
      {isLeftPosition && (
        <S.FeedbackControls>
          {!isDisliked && (
            <S.LikeWrapper>
              <LikeIcon isDisabled={isLiked} onClick={handleLikeClick} />
              {!isLiked && <S.Tooltip>{message("likeTooltip")}</S.Tooltip>}
            </S.LikeWrapper>
          )}
          {!isLiked && (
            <S.DislikeWrapper>
              <DislikeIcon
                isDisabled={isDisliked}
                onClick={handleDislikeClick}
              />
              {!isModalOpen && !isDisliked && (
                <S.Tooltip>{message("dislikeTooltip")}</S.Tooltip>
              )}
              {isModalOpen && (
                <FeedbackModal
                  onClose={() => setIsModalOpen(false)}
                  onSubmit={handleFeedbackSubmit}
                />
              )}
            </S.DislikeWrapper>
          )}
        </S.FeedbackControls>
      )}
    </S.Wrapper>
  );
};
