import React from "react";
import { colors } from "../../styles/colors";

interface NewConversationIconProps {
  color?: string;
}

export const NewConversationIcon = ({
  color = colors.contrast.light,
}: NewConversationIconProps) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91764 16.2971L4.10966 15.6991L3.74409 15.1882C2.71976 13.7569 2.11717 12.005 2.11717 10.1086C2.11717 5.28206 6.02986 1.36936 10.8564 1.36936C15.683 1.36936 19.5957 5.28206 19.5957 10.1086C19.5957 11.2025 19.3947 12.2494 19.0276 13.2145C19.4247 13.363 19.7986 13.5587 20.1427 13.7949C20.5958 12.6543 20.8449 11.4106 20.8449 10.1086C20.8449 4.59212 16.3729 0.120117 10.8564 0.120117C5.33992 0.120117 0.86792 4.59212 0.86792 10.1086C0.86792 12.2743 1.55714 14.2789 2.72819 15.9153L1.62176 19.3616C1.45221 19.8897 2.00381 20.3604 2.49865 20.1098L5.54317 18.5683C7.08206 19.5368 8.9038 20.0971 10.8564 20.0971C11.3765 20.0971 11.8873 20.0574 12.3859 19.9807C12.2337 19.5957 12.1262 19.1881 12.0698 18.7643C11.6732 18.8194 11.2682 18.8479 10.8564 18.8479C9.1461 18.8479 7.55406 18.3578 6.2086 17.511L5.60993 17.1342L4.97884 17.4537L3.26826 18.3199L3.91764 16.2971Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9286 18.0742C21.9286 20.6795 19.8165 22.7916 17.2111 22.7916C14.6057 22.7916 12.4937 20.6795 12.4937 18.0742C12.4937 15.4688 14.6057 13.3567 17.2111 13.3567C19.8165 13.3567 21.9286 15.4688 21.9286 18.0742ZM16.7953 17.6969V15.8292H17.7953V17.6969H19.6958V18.6969H17.7953V20.6501H16.7953V18.6969H14.8749V17.6969H16.7953Z"
        fill={color}
      />
    </svg>
  );
};
