import React, { useState } from "react";
import * as S from "./LanguageDropdown.styled";
import { useLanguage } from "../../context/LanguageContext";

export const LanguageDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale, changeLanguage } = useLanguage();

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (lang: string) => {
    changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <S.DropdownWrapper>
      <S.DropdownButton onClick={toggleDropdown}>
        {locale.toUpperCase()}
      </S.DropdownButton>
      {isOpen && (
        <S.DropdownMenu>
          <li onClick={() => handleSelect("en")}>EN</li>
          <li onClick={() => handleSelect("de")}>DE</li>
          <li onClick={() => handleSelect("fr")}>FR</li>
        </S.DropdownMenu>
      )}
    </S.DropdownWrapper>
  );
};
