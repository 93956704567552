type Result = {
  offerUrl: string;
  imageUrl: string;
  title: string;
  price?: string;
  price_without_vat?: string;
};

export type Annotation = {
  type: "file_citation";
  text: string;
  start_index: number;
  end_index: number;
  filename: string;
  file_citation: {
    file_id: string;
  };
};

export type Attachment = {
  summary: string;
  results: Result[];
};

export enum MessageStatus {
  None = "none",
  Liked = "like",
  Disliked = "dislike",
}

export type MessageT = {
  messageId?: string;
  content: string;
  position: "left" | "right";
  attachments?: Attachment[];
  annotations?: Annotation[];
  status: MessageStatus;
};

type ThreadId = string;
interface Content {
  type: "text";
  value: string;
  annotations?: Annotation[];
}

export interface AssistantMessage {
  id: string;
  role: string;
  content: Content[];
  threadId?: ThreadId;
}

export type APIResponseT = {
  messages: AssistantMessage[];
  threadId: ThreadId;
};
