import React, { createContext, useContext, useState, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "../locales/en.json";
import frMessages from "../locales/fr.json";
import deMessages from "../locales/de.json";

const messages: Record<string, Record<string, string>> = {
  en: enMessages,
  fr: frMessages,
  de: deMessages,
};

interface LanguageContextProps {
  locale: string;
  changeLanguage: (language: string) => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [locale, setLocale] = useState("en");

  const changeLanguage = (language: string) => {
    setLocale(language);
  };

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
