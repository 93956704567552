import React from "react";
import * as S from "./LogoutButton.styled";
import { LogoutIcon } from "../LogoutIcon";
import { useTranslation } from "../../hooks";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasIcon?: boolean;
}

export const LogoutButton = ({ hasIcon, ...props }: ButtonProps) => {
  const { message } = useTranslation();

  return (
    <S.LogoutButton {...props}>
      {hasIcon && (
        <S.IconWrapper>
          <LogoutIcon />
        </S.IconWrapper>
      )}
      <S.Text>{message("logout")}</S.Text>
    </S.LogoutButton>
  );
};
