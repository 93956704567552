import React from "react";
import * as S from "./ChatHeader.styled";
import { LanguageDropdown, NewConversationIcon } from "..";
import LogoImg from "./logo.png";
import { useAssistantChat } from "../../context";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { LogoutButton } from "../LogoutButton";

export const ChatHeader = () => {
  const navigate = useNavigate();
  const { createNewConversation, clearThread } = useAssistantChat();
  const { onLogout } = useAuth();

  const handleLogout = async () => {
    await clearThread();
    onLogout();
    navigate("/login");
  };

  const handleNewConversation = async () => {
    console.log(`Creating new conversation from ChatHeader`);
    createNewConversation();
  };

  const handleLogoClick = async () => {
    await clearThread();
    navigate("/");
  };

  return (
    <S.Wrapper>
      <S.Logo src={LogoImg} alt="logo" onClick={handleLogoClick} />

      <S.LanguageNewChatWrapper>
        <S.NewConversationButton
          icon={<NewConversationIcon />}
          onClick={() => {
            handleNewConversation();
          }}
        />
        <LanguageDropdown />
        <LogoutButton hasIcon onClick={handleLogout} />
      </S.LanguageNewChatWrapper>
    </S.Wrapper>
  );
};
