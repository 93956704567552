import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";
import { BubbleIcon as StyledBubbleIcon } from "./BubbleIcon";

export const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  transition: all 0.3s ease;
`;

export const ChatWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 0px 200px;
  margin-top: 7rem;
`;

export const Main = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* overflow-y: hidden;
  overflow-x: hidden; */

    transition: all 0.3s ease;

    &::-webkit-scrollbar-track {
      background: ${theme.colors.contrast.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background: ${theme.colors.contrast.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, and Opera */
    }
  `
);

export const Footer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 0;
    bottom: 0;
    transition: all 1s ease;
    background-color: ${theme.colors.contrast.light};
    z-index: 10001;
  `
);

export const IntroMessage = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.contrast.dark};
    font-size: 24px;
    font-weight: 400;
    margin-left: 1.5rem;
    width: 45%;
    position: relative;

    ${mediaQueries.mobile} {
      width: 55%;
    }
  `
);

export const Bubble = styled(StyledBubbleIcon)`
  position: absolute;
  z-index: -1;
  transform: translate(-80%, -25%);
`;

export const InputWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.contrast.light};
    width: 100%;
    max-width: 850px;
    padding: 0.75rem 0 1.25rem 0;
  `
);
