import { useState, useEffect } from "react";

type AuthContextType = {
  isAuthenticated: boolean;
  loading: boolean;
  onLogin: (email: string, password: string) => boolean;
  onLogout: () => void;
  errorMessage: string;
};

export const useAuth = (): AuthContextType => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const authState = localStorage.getItem("isAuthenticated");
    setIsAuthenticated(authState === "true");
    setLoading(false);
  }, []);

  const onLogin = (email: string, password: string) => {
    if (email === "admin@suprabit.eu" && password === "FritzGPT123$") {
      setIsAuthenticated(true);
      setErrorMessage("");
      localStorage.setItem("isAuthenticated", "true");
      console.log("Login successful, isAuthenticated set to true");
      return true;
    } else {
      setErrorMessage("Invalid credentials");
      setIsAuthenticated(false);
      localStorage.removeItem("isAuthenticated");
      return false;
    }
  };

  const onLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
  };

  return { isAuthenticated, loading, onLogin, onLogout, errorMessage };
};
