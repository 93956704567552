import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "../../hooks";

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuth();
  const { message } = useTranslation();

  if (loading) {
    return <div>{message("loading")}</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};
