import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const LogoutButton = styled.button(
  ({ theme }) => css`
    display: flex;
    gap: 5px;
    color: ${theme.colors.brand.primary.main};
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 1rem;
    }

    ${mediaQueries.mobile} {
      background-color: ${theme.colors.contrast.light};
      opacity: 0.9;
      padding: 0.3rem 0.5rem;
      border-radius: 5px;
    }
  `
);

export const Text = styled.span`
  font-size: 13.117px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
