import React from "react";
import * as S from "./LandingFooter.styled";
import { FooterLogo } from "./FooterLogo";
import { useTranslation } from "../../hooks";

export const LandingFooter = () => {
  const { message } = useTranslation();

  return (
    <S.Wrapper>
      <FooterLogo />
      <span>
        <p>
          {message("copyrightText")} {new Date().getFullYear()} Suprabit Ltd.
        </p>
        <p>{message("allRightsReserved")}</p>
        <a
          href="https://www.sauter-controls.com/"
          target="_blank"
          rel="noreferrer"
        >
          {message("footerLinkText")}
        </a>
      </span>
    </S.Wrapper>
  );
};
