import React from "react";
import * as S from "./Input.styled";
import { SendButton } from "..";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  focusInput?: (input: HTMLInputElement) => void;
  onSend?: () => void;
  isDisabled: boolean;
  hasIcon: boolean;
}

export const Input: React.FC<InputProps> = ({
  hasIcon,
  focusInput,
  onSend,
  ...props
}) => {
  return (
    <S.Wrapper disabled={props.disabled || false}>
      <S.Input
        {...props}
        ref={(input: HTMLInputElement) => {
          if (input && focusInput) {
            focusInput(input);
          }
        }}
      />
      {hasIcon && <SendButton onClick={onSend} isDisabled={props.isDisabled} />}
    </S.Wrapper>
  );
};
