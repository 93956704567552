import styled, { css } from "styled-components";

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button(
  ({ theme }) => css`
    background-color: ${theme.colors.brand.primary.main};
    color: ${theme.colors.contrast.light};
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      background-color: ${theme.colors.brand.primary.dark};
    }
  `
);

export const DropdownMenu = styled.ul(
  ({ theme }) => css`
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: ${theme.colors.contrast.light};
    border: 1px solid ${theme.colors.brand.primary.main};
    border-radius: 4px;
    width: 100%;
    z-index: 200;

    & > li {
      padding: 8px 16px;
      cursor: pointer;
      color: ${theme.colors.brand.primary.main};
      font-weight: bold;

      &:hover {
        background-color: ${theme.colors.brand.primary.light};
      }
    }
  `
);
