import React from "react";
import { colors } from "../../styles/colors";

export const BubbleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="163"
    height="54"
    viewBox="0 0 163 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.442383"
      y="0.32959"
      width="162.073"
      height="53.2494"
      rx="26.6247"
      fill={colors.brand.secondary.main}
    />
  </svg>
);
