import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Home = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100dvh;
  opacity: 1;

  font-family: "Jost";

  ${mediaQueries.mobile} {
    position: relative;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
`;

export const LandingHeading = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.contrast.light};
    color: ${theme.colors.contrast.light};
    font-family: "Jost", sans-serif;
    padding: 0.25rem 0.5rem;
    margin: 5rem 0 3rem 2rem;
    align-self: flex-start;

    & > h2 {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
    }

    ${mediaQueries.tablet} {
      margin: 4rem 0 3rem 5rem;
      padding: 0.2rem 0.4rem;
      font-size: 0.9rem;
    }

    ${mediaQueries.mobile} {
      display: none;
    }
  `
);

export const LandingDescriptionContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.brand.primary.main};
    padding: 1.2rem 2.4rem;
    border-radius: 12px;
    color: ${theme.colors.contrast.light};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin: 2rem 0;
    width: 30rem;

    ${mediaQueries.tablet} {
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 0;
      margin-top: 3rem;
    }

    ${mediaQueries.mobile} {
      width: calc(90% - 5rem);
    }
  `
);
export const LandingDescriptionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20rem;
  gap: 1rem;
  margin-bottom: 0.5rem;
  z-index: 1;

  & > h2 {
    font-size: 1.7rem;
    font-weight: 600;
    margin: 0;
    line-height: 130%;
  }
`;

export const LandingDescription = styled.div`
  font-size: 1.15rem;
  font-weight: 400;
  width: 30rem;
  max-width: 30rem;
  text-align: left;
  line-height: 140%;
  z-index: 1;

  ${mediaQueries.tablet} {
    width: 60%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const SuggestionsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  & > p {
    margin: 0;
  }

  & > label {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const Suggestion = styled.p(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 0.7rem;
    border-radius: 3rem;
    border: 1px solid ${theme.colors.contrast.middle};
    font-weight: 500;
    padding: 0.4rem 0.75rem;
    color: ${theme.colors.contrast.middle};
    background-color: ${theme.colors.contrast.light};
    width: fit-content;
    cursor: pointer;
  `
);

export const InputWrapper = styled.div`
  width: min(45rem, 100%);
  max-width: 850px;
  padding: 0 0 1.5rem;
`;

export const InputTitleLabel = styled.div`
  position: relative;
  top: 0.6rem;
  left: 0.7rem;
  align-self: flex-start;
  margin: 0 1.25rem;
`;

export const StyledText = styled.p(
  ({ theme }) => css`
    margin: 0;
    font-size: 2.8rem;
    position: relative;
    letter-spacing: -1px;
    font-weight: 800;
    color: transparent;
    -webkit-text-stroke: 1px ${theme.colors.brand.primary.main};
    font-family: Arial, sans-serif;
  `
);
