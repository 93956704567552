import { useIntl } from "react-intl";
import { useCallback } from "react";

export type TranslationValues = Record<string, any>;

export function useTranslation() {
  const { formatMessage } = useIntl();

  const message = useCallback(
    (id: string, values?: TranslationValues) => {
      return id ? formatMessage({ id }, values) : "";
    },
    [formatMessage]
  );

  return { message };
}
