import React, { useState } from "react";
import * as S from "./LandingHeader.styled";
import LogoImg from "./logo.png";
import { AssistantToggle } from "../AssistantToggle";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useAssistantChat } from "../../context";
import { LogoutButton } from "../LogoutButton";
import { LanguageDropdown } from "../LanguageDropdown";

export const LandingHeader = () => {
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  const { clearThread } = useAssistantChat();

  const handleLogout = async () => {
    await clearThread();
    onLogout();
    navigate("/login");
  };

  return (
    <S.Wrapper>
      <S.Logo src={LogoImg} alt="logo" />
      {/* <AssistantToggle /> */}
      <S.LanguageLogoutWrapper>
        <LanguageDropdown />
        <LogoutButton hasIcon onClick={handleLogout} />
      </S.LanguageLogoutWrapper>
    </S.Wrapper>
  );
};
