import React, { useEffect, useState } from "react";
import LogoImg from "./logo.png";
import * as S from "./Login.styled";
import { Input } from "../Input";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks";

export const Login = () => {
  const { onLogin, errorMessage, isAuthenticated } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { message } = useTranslation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isLoggedIn = onLogin(email, password);
    if (isLoggedIn) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <S.LoginContainer>
      <S.FormContainer>
        <S.Logo src={LogoImg} alt="logo" />
        <S.Form onSubmit={handleSubmit}>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={message("emailPlaceholder")}
            hasIcon={false}
            isDisabled={false}
          />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={message("passwordPlaceholder")}
            hasIcon={false}
            isDisabled={false}
          />
          <S.Button type="submit">{message("login")}</S.Button>
          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        </S.Form>
      </S.FormContainer>
    </S.LoginContainer>
  );
};
