import styled, { css } from "styled-components";
import { Button as StyledButton } from "..";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: min(80rem, 85%);

    position: fixed;
    top: 0;
    z-index: 101;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 4rem 1rem 4rem;
    background: ${theme.colors.contrast.light};

    ${mediaQueries.mobile} {
      width: 95%;
    }
  `
);

export const Logo = styled.img`
  width: 180px;
  cursor: pointer;

  ${mediaQueries.mobile} {
    width: 140px;
  }
`;

export const NewConversationButton = styled(StyledButton)(
  ({ theme }) => css`
    background: ${theme.colors.brand.primary.main};
    border: none;
  `
);

export const LanguageNewChatWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
