import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const ContainerMobile = styled.div`
  width: 100%;

  justify-content: flex-end;
  align-items: center;
  display: none;

  ${mediaQueries.mobile} {
    display: flex;
  }
`;

export const ContainerDesktop = styled.div`
  position: fixed;
  bottom: -20rem;
  right: -20rem;
  width: 120rem;
  pointer-events: none;
  z-index: -1;
  display: block;

  ${mediaQueries.mobile} {
    display: none;
  }

  ${mediaQueries.desktopLarge} {
    width: 100rem;
  }

  ${mediaQueries.desktopLargeLarge} {
    width: 130rem;
  }

  ${mediaQueries.tablet} {
    width: 90rem;
  }
`;

export const DesktopHeroIconsWrapper = styled.div`
  position: absolute;
  top: 10%;
  right: 27%;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(79, 103, 227, 0.5);
  z-index: 1;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: -8rem;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  overflow: visible;

  ${mediaQueries.tablet} {
    height: 60vh;
    left: -5%;

    svg {
      width: 110%;
    }
  }

  ${mediaQueries.mobile} {
    height: 40vh;
    top: -10rem;
    left: -5rem;

    & > div {
      width: 50rem;
    }
  }
`;

export const HeroTitle = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 2rem;
    font-weight: 500;
    text-align: right;
    color: ${theme.colors.contrast.light};

    padding: 0.5rem 3rem;
    margin-right: 2rem;

    ${mediaQueries.tablet} {
      font-size: 2rem;
      padding-top: 5rem;
    }

    ${mediaQueries.mobile} {
      align-items: center;
      text-align: center;
      font-size: 1.4rem;
      margin-top: 5.5rem;
      width: 100%;
      margin-right: 0;
    }
  `
);

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const VideoWrapper = styled.div`
  position: "relative";
  width: "100%";
  height: "100%";
`;
