import React from "react";
import * as S from "./Button.styled";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
}

export const Button = ({ icon, ...props }: ButtonProps) => {
  return (
    <S.Button {...props}>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      {props.children && <S.Text>{props.children}</S.Text>}
    </S.Button>
  );
};