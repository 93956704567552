import React from "react";
import * as S from "./CookieNotice.styled";
import { useTranslation } from "../../hooks";

type CookieNoticeProps = {
  onClick: () => void;
};

export const CookieNotice = ({ onClick }: CookieNoticeProps) => {
  const { message } = useTranslation();

  return (
    <S.Wrapper>
      <S.Container>
        <S.Text>
          {message("cookieNoticeText")}{" "}
          <a
            href="https://www.sauter-controls.com/en/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {message("privacyPolicy")}
          </a>
          .
        </S.Text>
        <S.StyledButton onClick={onClick}>
          {message("cookieNoticeButton")}
        </S.StyledButton>
      </S.Container>
    </S.Wrapper>
  );
};
