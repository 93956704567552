import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

export const ModalContent = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.brand.primary.main};
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    color: ${theme.colors.contrast.light};
  `
);

export const StyledTextarea = styled.textarea(
  ({ theme }) => css`
    font-family: "Arial", sans-serif;
    background: ${theme.colors.contrast.light};
    border: 1px solid ${theme.colors.brand.primary.main};
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    outline: none;
    width: 300px;

    &::placeholder {
      font-size: 14px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  `
);

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const StyledButton = styled.button(
  ({ theme }) => css`
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: ${theme.colors.contrast.light};
    font-weight: bold;
    padding: 5px;

    &:hover {
      background-color: ${theme.colors.contrast.light};
      color: ${theme.colors.brand.primary.main};
    }
  `
);
export const ErrorMessage = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.brand.secondary.main};
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  `
);
