import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

type WrapperProps = {
  position: "left" | "right";
  withoutMarginBottom?: boolean;
};

const getArrowStyles = (position: "left" | "right") => {
  if (position === "left") {
    return css`
      background-color: ${({ theme }) => theme.colors.brand.secondary.main};
      left: -10px;
    `;
  } else {
    return css`
      background-color: ${({ theme }) => theme.colors.brand.primary.main};
      right: -10px;
    `;
  }
};

export const ContentWrapper = styled.div<WrapperProps>`
  background-color: "#F4F4F4";
  color: "#363636";
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 11px;
  border-radius: 11px;
  max-width: 440px;
  word-break: break-word;
  position: relative;
  margin: 7px 15px 0 15px;
  white-space: pre-line;
  ${({ position }) =>
    position === "left" ? "text-align: left" : "text-align: right"};

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    top: 0px;
    ${({ position }) => getArrowStyles(position)}
  }

  p {
    margin: 0;
  }

  ol,
  ul {
    white-space: normal;
  }

  li {
    margin-top: 15px;
    margin-right: 10px;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 11px;
    margin-top: 10px;
  }

  a {
    color: "#363636";
    text-decoration: underline;
  }

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

export const Source = styled.div`
  a {
    text-decoration: none;
    margin-right: 6px;
  }
`;

export const Wrapper = styled.div<WrapperProps>(
  ({ withoutMarginBottom, position }) => css`
    margin: 10px;
    ${withoutMarginBottom &&
    css`
      margin-bottom: 0;
    `}
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* animated appear */
    animation: appear 0.2s ease-in-out;
    animation-fill-mode: forwards;
    align-self: ${position === "left" ? "flex-start" : "flex-end"};

    @keyframes appear {
      0% {
        opacity: 0;
        transform: translateX(${position === "left" ? "-100%" : "100%"});
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `
);

export const FeedbackControls = styled.div`
  margin-left: 25px;
`;

export const Tooltip = styled.div(
  ({ theme }) => css`
    visibility: hidden;
    background-color: ${theme.colors.brand.primary.main};
    color: ${theme.colors.contrast.light};
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    white-space: nowrap;
    position: absolute;
    left: 100%;
    top: -5px;
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent ${theme.colors.brand.primary.main} transparent
        transparent;
    }
  `
);
export const LikeWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 15px;

  &:hover > ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

export const DislikeWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover > ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;
